import { toast } from 'react-toastify';
import ApiDatabase from 'server';

const useImportedTimeSheet = () => {
  /**
   * toggleImported effectue la requête pour marquer/unmarquer un relevé comme importé.
   * @param {boolean} isImported - True pour marquer comme importé, false sinon.
   * @param {string} idTimeSheet - L'identifiant du relevé.
   * @param {Function} updateCallback - Fonction callback qui met à jour le state dans le composant appelant.
   */
  const toggleImported = (isImported, idTimeSheet, updateCallback) => {
    const token = localStorage.getItem('xsrfToken');
    ApiDatabase.postImportedTimeSheet(
      { token, idTimeSheet, isImported },
      () => {
        updateCallback(isImported);
        toast.success(
          isImported
            ? 'Relevé marqué comme importé'
            : 'Relevé marqué comme à importer'
        );
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const timeSheetVerified = location.pathname.includes('timeSheet/checked');

  return { toggleImported, timeSheetVerified };
};

export default useImportedTimeSheet;
