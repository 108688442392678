// Custom hook: useMissionPeriod
// Provides state management and business logic for mission search and updates.
import useSlideOver from 'components/SlideOver/useSlideOver';
import useModal from 'components/modal/useModal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiDatabase from 'server';
import UseContext from './useContext';
import Usefetchdata from './usefetchdata';

export default function useMissionPeriod() {
  // External context and dependencies
  const { objID, setObjID, searchMissionResult, setSearchMissionResult } =
    UseContext();
  const { toggleSlideOver } = useSlideOver();
  const { toggle } = useModal();
  const { userData } = Usefetchdata();
  const token = localStorage.getItem('xsrfToken');

  // Local state definitions
  const [searchMissionResultWaiting, setSearchMissionResultWaiting] =
    useState(false);
  const [isMissionSearchAssigned, setIsMissionSearchAssigned] = useState(null);
  const [isMissionSearchSigned, setIsMissionSearchSigned] = useState(null);
  const [isMissionSearchSignatureSend, setIsMissionSearchSignatureSend] =
    useState(null);
  const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(false);
  const [isInfoMissionLoadData, setIsInfoMissionLoadData] = useState(false);
  const [editMission, setEditMission] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limitElement] = useState(10);
  const [countPerPage, setCountPerPage] = useState(10);
  const [countTotal, setCountTotal] = useState(0);
  const [hoveredCompanyItems, setHoveredCompanyItems] = useState({});

  const [search, setSearch] = useState('');

  // Contract type filter options
  const [typeContract, setTypeContract] = useState([
    { type: 'sendToSign', name: 'Envoyé pour signature', checked: false },
    {
      type: 'toBeSentToSign',
      name: 'À envoyer pour signature',
      checked: false,
    },
    { type: 'signByCompany', name: 'Signé par l’entreprise', checked: false },
    {
      type: 'signByTempWorker',
      name: 'Signé par l’intérimaire',
      checked: false,
    },
    { type: 'notCreated', name: 'Contrat non généré', checked: false },
  ]);

  // Department filter options
  const [departments, setDepartments] = useState([
    { name: 'Martinique', checked: false },
    { name: 'Guadeloupe', checked: false },
  ]);

  // Mission status filter options
  const [missionStatus, setMissionStatus] = useState([
    { type: 'all', name: 'Tous les status', checked: true },
    { type: 'done', name: 'Terminé', checked: false },
    { type: 'inProgress', name: 'En cours', checked: false },
    { type: 'soon', name: 'À venir', checked: false },
  ]);

  const [role, setRole] = useState(500);
  const [selectedMissions, setSelectedMissions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesSearch, setEmployeesSearch] = useState([]);

  // Update role state from userData
  useEffect(() => {
    if (userData?.role) {
      setRole(userData.role);
    }
  }, [userData]);

  // Fetch department missions from the API
  const getDepartmentMissions = () => {
    ApiDatabase.getDepartmentMissions(
      { token },
      (data) => {
        if (data.departments.length > 0) {
          const deps = data.departments.map((department) => ({
            name: department,
            checked: false,
          }));
          setDepartments(deps);
        }
      },
      (err) => {
        if (err.name !== 'CanceledError') console.error(err);
      }
    );
  };

  // Function to perform mission search based on filters and search input
  const manageMissionSearch = () => {
    setSearchMissionResultWaiting(true);
    setIsInfoMissionLoadData(false);
    setIsInfoMissionWaiting(false);
    setEditMission(false);

    ApiDatabase.searchMission(
      {
        token,
        search,
        isMissionSearchAssigned,
        typeContract: typeContract
          .filter((item) => item.checked)
          .map((item) => item.type),
        departments: departments
          .filter((item) => item.checked)
          .map((item) => item.name),
        missionStatus: missionStatus
          .filter((item) => item.checked)
          .map((item) => item.type),
        employeesSearch: employeesSearch
          .filter((item) => item.checked)
          .map((item) => item._id),
        limit: limitElement,
        page: currentPage,
      },
      (data) => {
        // Update search results and pagination info on success
        setSearchMissionResult(data.missions);
        setCountTotal(data.count);
        setCountPerPage(data.countPerPage);
        setTotalPages(data.countPages);
        if (data.missions.length === 1) {
          setEditMission(data.missions[0].id_mission_real);
        }
        setSearchMissionResultWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          setSearchMissionResultWaiting(false);
          setSearchMissionResult([]);
          console.error(err);
        }
      }
    );
  };

  // Effects to trigger search when filters or pagination change
  useEffect(() => {
    manageMissionSearch();
  }, [
    isMissionSearchAssigned,
    typeContract,
    departments,
    missionStatus,
    employeesSearch,
    currentPage,
  ]);

  // Debounce search input change to reduce API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      manageMissionSearch();
    }, 400);
    return () => clearTimeout(timer);
  }, [search]);

  // Reset to first page when any filter or search input changes
  useEffect(() => {
    setCurrentPage(1);
  }, [
    isMissionSearchAssigned,
    typeContract,
    departments,
    missionStatus,
    employeesSearch,
    search,
  ]);

  // Initial fetch of department missions
  useEffect(() => {
    getDepartmentMissions();
  }, []);

  // Reset related search states when mission assignment filter is false
  useEffect(() => {
    if (isMissionSearchAssigned === false) {
      setIsMissionSearchSigned(false);
      setIsMissionSearchSignatureSend(false);
    }
  }, [isMissionSearchAssigned]);

  // Automatically update assignment filters based on signature status
  useEffect(() => {
    if (isMissionSearchSigned === true) {
      setIsMissionSearchAssigned(true);
      setIsMissionSearchSignatureSend(true);
    }
  }, [isMissionSearchSigned]);

  useEffect(() => {
    if (isMissionSearchSignatureSend === true) {
      setIsMissionSearchAssigned(true);
    } else if (isMissionSearchSignatureSend === false) {
      setIsMissionSearchSigned(false);
    }
  }, [isMissionSearchSignatureSend]);

  // Opens the company info slide-over panel
  const openCompanyInfo = (id) => {
    setObjID(id);
    toggleSlideOver('get_company');
  };

  // Set default search parameters based on user role
  useEffect(() => {
    if (role === 525) {
      setIsMissionSearchAssigned(true);
      setIsMissionSearchSignatureSend(false);
    }
  }, [role]);

  // Determine which trash button to show based on mission state
  const shouldShowTrashButton = (mission) => {
    if (mission.status === 'cancel') return 'delete';

    const now = new Date();
    const startDate = new Date(mission.duration.datestart);
    const endDate = new Date(mission.duration.dateend);
    const hasAssignedUser = mission.user
      ? typeof mission.user === 'object'
      : false;

    let missionStatusLocal;
    if (now < startDate) {
      missionStatusLocal = 'soon';
    } else if (now > endDate) {
      missionStatusLocal = 'past';
    } else {
      missionStatusLocal = 'in_progress';
    }

    if (missionStatusLocal === 'soon' && hasAssignedUser) return 'cancel';
    if (
      (missionStatusLocal === 'in_progress' ||
        missionStatusLocal === 'soon' ||
        missionStatusLocal === 'past') &&
      !hasAssignedUser
    )
      return 'delete';

    return false;
  };

  // Open delete mission modal
  const handleDeleteMission = (idMission) => {
    setObjID(idMission);
    toggle('mission_delete');
  };

  // Open cancel mission modal
  const handleCancelMission = (idMission) => {
    setObjID(idMission);
    toggle('mission_cancel');
  };

  // Update the contract type filter state based on user selection
  const updateTypeContract = (updatedTypeContract) => {
    const updated = [...typeContract];
    for (let i = 0; i < updated.length; i++) {
      for (let j = 0; j < updatedTypeContract.length; j++) {
        if (updated[i].type === updatedTypeContract[j].value) {
          updated[i].checked = updatedTypeContract[j].checked;
        }
      }
    }
    if (updated.some((item) => item.checked)) {
      setIsMissionSearchAssigned(true);
    }
    setTypeContract(updated);
  };

  // Update the departments filter state
  const updateDepartment = (updatedDepartment) => {
    const updated = [...departments];
    for (let i = 0; i < updated.length; i++) {
      for (let j = 0; j < updatedDepartment.length; j++) {
        if (updated[i].name === updatedDepartment[j].value) {
          updated[i].checked = updatedDepartment[j].checked;
        }
      }
    }
    setDepartments(updated);
  };

  // Update the mission status filter state
  const updateMissionStatus = (updatedMissionStatus) => {
    const updated = [...missionStatus];
    for (let i = 0; i < updated.length; i++) {
      for (let j = 0; j < updatedMissionStatus.length; j++) {
        if (updated[i].type === updatedMissionStatus[j].value) {
          updated[i].checked = updatedMissionStatus[j].checked;
        }
      }
    }
    setMissionStatus(updated);
  };

  // Opens the temporary worker info slide-over panel
  const openTempWorkerInfo = (id) => {
    setObjID(id);
    toggleSlideOver('get_temp_worker');
  };

  // Handles the selection of missions for bulk actions
  const handleSelectMission = (missionId, checked) => {
    setSelectedMissions((prev) => {
      if (checked) return [...prev, missionId];
      return prev.filter((id) => id !== missionId);
    });
  };

  // Fetch employees from the API and update state
  useEffect(() => {
    ApiDatabase.getEmployeesToAssignMission(
      { token },
      (data) => {
        if (data.employees.length > 0) {
          const updated = data.employees
            .map((employee) => ({ ...employee, checked: false }))
            .sort((a, b) => a.firstname.localeCompare(b.firstname));
          // Add an option for "unassigned"
          updated.push({
            firstname: 'Non assigné',
            lastname: '',
            checked: false,
            _id: 'unassign',
          });
          setEmployees(updated);
          setEmployeesSearch(updated);
        }
      },
      (err) => {
        if (err.name !== 'CanceledError') console.error(err);
      }
    );
  }, [token]);

  // Update employee search filter state, handling special case for unassigned
  const updateEmployeeSearch = (updatedEmployeeSearch) => {
    // Clone employeesSearch array to avoid direct state mutation
    const updated = employeesSearch.map((emp) => ({ ...emp }));

    // Create a mapping object for quick access to updatedEmployeeSearch items
    const searchMap = updatedEmployeeSearch.reduce((acc, item) => {
      acc[item.value] = item;
      return acc;
    }, {});

    // Check if 'unassign' is checked in updatedEmployeeSearch
    const isUnassignInUpdatedEmployeeSearchChecked = !!(
      searchMap['unassign'] && searchMap['unassign'].checked
    );
    // Find the 'unassign' item in the cloned updated array
    const unassignItem = updated.find((item) => item._id === 'unassign');
    // Check if 'unassign' is checked in updated array
    const isUnassignInUpdatedChecked = unassignItem && unassignItem.checked;

    if (
      isUnassignInUpdatedEmployeeSearchChecked &&
      !isUnassignInUpdatedChecked
    ) {
      // If 'unassign' is checked in updatedEmployeeSearch but not in updated:
      // Check 'unassign' and uncheck all others.
      updated.forEach((item) => {
        item.checked = item._id === 'unassign';
      });
    } else if (!isUnassignInUpdatedEmployeeSearchChecked) {
      // If 'unassign' is not checked in updatedEmployeeSearch:
      // Update each item based on the mapping.
      updated.forEach((item) => {
        if (searchMap[item._id] !== undefined) {
          item.checked = searchMap[item._id].checked;
        }
      });
    } else if (
      isUnassignInUpdatedEmployeeSearchChecked &&
      isUnassignInUpdatedChecked
    ) {
      // If 'unassign' is checked in both arrays:
      // Uncheck 'unassign' in updated and update the other items.
      updated.forEach((item) => {
        if (item._id === 'unassign') {
          item.checked = false;
        } else if (searchMap[item._id] !== undefined) {
          item.checked = searchMap[item._id].checked;
        }
      });
    }

    setEmployeesSearch(updated);
  };

  // Assigns an employee to multiple selected missions
  const updateEmployee = (updatedEmployee) => {
    const selectedEmployee = updatedEmployee.find(
      (employee) => employee.checked
    );
    const newEmployeeId = selectedEmployee.value;

    ApiDatabase.postMissionAdminEmployeeAssigned(
      {
        token,
        idEmployee: newEmployeeId,
        idMissions: selectedMissions,
      },
      (data) => {
        setSearchMissionResult((prevResults) =>
          prevResults.map((mission) =>
            selectedMissions.includes(mission.id_mission_real)
              ? {
                  ...mission,
                  admin_employee_assigned:
                    newEmployeeId === 'unassign' ? null : newEmployeeId,
                }
              : mission
          )
        );
        setSelectedMissions([]);
        toast.success('Assignation effectuée');
      },
      (err) => {
        if (err.name !== 'CanceledError') console.error(err);
      }
    );
  };

  // Assigns an employee to a single mission and updates the result list
  const updateEmployeeForMission = (missionId, updatedEmployee) => {
    const selectedEmployee = updatedEmployee.find(
      (employee) => employee.checked
    );
    const newEmployeeId = selectedEmployee.value;

    ApiDatabase.postMissionAdminEmployeeAssigned(
      {
        token,
        idEmployee: newEmployeeId,
        idMissions: [missionId],
      },
      (data) => {
        setSearchMissionResult((prevMissions) =>
          prevMissions.map((mission) =>
            mission.id_mission_real === missionId
              ? {
                  ...mission,
                  admin_employee_assigned:
                    newEmployeeId === 'unassign' ? null : newEmployeeId,
                }
              : mission
          )
        );

        toast.success('Assignation effectuée');
      },
      (err) => console.error(err)
    );
  };

  // Return all states and functions needed by the MissionPeriod component
  return {
    search,
    setSearch,
    searchMissionResult,
    searchMissionResultWaiting,
    isMissionSearchAssigned,
    setIsMissionSearchAssigned,
    typeContract,
    updateTypeContract,
    departments,
    updateDepartment,
    missionStatus,
    updateMissionStatus,
    employees,
    employeesSearch,
    updateEmployee,
    updateEmployeeSearch,
    openCompanyInfo,
    openTempWorkerInfo,
    handleDeleteMission,
    handleCancelMission,
    handleSelectMission,
    selectedMissions,
    shouldShowTrashButton,
    updateEmployeeForMission,
    currentPage,
    setCurrentPage,
    totalPages,
    countPerPage,
    countTotal,
    editMission,
    setEditMission,
    hoveredCompanyItems,
    setHoveredCompanyItems,
    setSearchMissionResult,
  };
}
