import { ArrowLeft, Loading2, SearchLoop } from 'assets/icons/index.js';
import axios from 'axios';
import useModal from 'components/modal/useModal.js';
import Pagination from 'components/pagination/pagination.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useAbortController from '../../../../../hooks/useAbortController';
import TableListVivier from './components/table.list.vivier.js';

function ListBodyVivier() {
  const { toggle } = useModal();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [search, setSearch] = useState('');
  const [selectMQ, setSelectMQ] = useState(false);
  const [selectGP, setSelectGP] = useState(false);
  const [searchVivierWaiting, setSearchVivierWaiting] = useState(true);
  const [sortName, setSortName] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [countPerPage, setCountPerPage] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [data, setData] = useState();
  const [result, setResult] = useState();
  const token = localStorage.getItem('xsrfToken');
  //get params from url
  const params = new URLSearchParams(window.location.search);
  const searchListVivier = params.get('searchListVivier');
  const pageListVivier = params.get('pageListVivier');
  const selectedVivier = params.get('selectedVivier');
  const getAbortController = useAbortController();

  // #region **** Handle Click ****
  const handleCreate = () => {
    toggle('vivier_create');
  };

  const handleSelectMartinique = (e) => {
    e.preventDefault();
    if (!selectMQ) {
      setSelectMQ(true);
      setSelectGP(false);
    } else {
      setSelectMQ(false);
      setSelectGP(false);
    }
  };

  const handleSelectGuadeloupe = (e) => {
    e.preventDefault();
    if (!selectGP) {
      setSelectMQ(false);
      setSelectGP(true);
    } else {
      setSelectMQ(false);
      setSelectGP(false);
    }
  };
  // #endregion **** Handle Click ****
  // #region **** Function ****
  const manageVivierSearch = async () => {
    setSearchVivierWaiting(true);
    const controller = getAbortController();

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}vivier/vivierAllPage`,
        {
          signal: controller.signal,
          params: {
            token: token,
            page: currentPage,
            limit: limitElement,
            GP: selectGP,
            MQ: selectMQ,
            search: search,
            sortName: sortName,
          },
        }
      );

      setTotalPages(data.countPages);
      setCountTotal(data.count);
      setCountPerPage(data.countPerPage);
      setData(data.count);
      setResult(data.viviers);
      setSearchVivierWaiting(false);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error('An error occurred:', error);
        setSearchVivierWaiting(false);
      }
    }
  };
  // #endregion **** Function ****
  // #region **** UseEffect ****
  let timerViverSearch = '';
  useEffect(() => {
    timerViverSearch = setTimeout(async () => {
      setCurrentPage(1);
      await manageVivierSearch();
    }, 400);
    return () => clearTimeout(timerViverSearch);
  }, [search]);

  useEffect(() => {
    manageVivierSearch();
  }, [selectGP, selectMQ, sortName, currentPage]);

  const navigateToFicheVivier = (idVivier) => {
    //if search and currentPage, add in the url
    navigate(
      `../ficheVivier/${idVivier}?searchListVivier=${search}&pageListVivier=${currentPage}`
    );
  };

  useEffect(() => {
    if (searchListVivier) setSearch(searchListVivier);
    if (pageListVivier)
      setCurrentPage(parseInt(pageListVivier) ? parseInt(pageListVivier) : 1);
  }, [searchListVivier, pageListVivier, selectedVivier]);

  // #endregion **** UseEffect ****

  return (
    <div className="flex flex-col space-y-8 h-full">
      <div className="flex flex-row items-center justify-between border-b border-gray-100 bg-white sticky top-0 z-20 py-3">
        <div className=" flex items-center gap-4">
          <NavLink to={'../vivier'}>
            <ArrowLeft w={24} h={12} color={'#374151'} />
          </NavLink>
          <div>{data ? data : 0} viviers</div>
        </div>
        <div className=" flex items-center gap-8">
          <div>
            <div className="relative text-gray-500 ">
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="w-48 h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                type="text"
                placeholder="Nom du vivier"
                required
              />
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <SearchLoop wh={18} color={'#666666'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='flex items-center gap-8'>
            <Chip type={selectMQ ?"SELECTON":"SELECTOFF"} icon select={selectMQ} onClick={handleSelectMartinique}>Martinique</Chip>
            <Chip type={selectGP ?"SELECTON":"SELECTOFF"} icon select={selectGP} onClick={handleSelectGuadeloupe}>Guadeloupe</Chip>
        </div> */}
      {searchVivierWaiting && (
        <div
          className={`flex flex-col ${
            width <= 460 ? 'gap-5' : 'gap-10'
          } justify-center items-center h-80`}
        >
          <div className={'animate-spin'}>
            <Loading2 wh={width <= 460 ? 40 : 60} color={'#FF9600'} />
          </div>
        </div>
      )}
      {!searchVivierWaiting && (
        <TableListVivier
          viviers={result}
          isWaiting={searchVivierWaiting}
          setSortName={setSortName}
          sortName={sortName}
          navigateToFicheVivier={navigateToFicheVivier}
        />
      )}
      {totalPages > 1 ? (
        <div className={'px-8 py-4'}>
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ListBodyVivier;
