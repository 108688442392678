import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import io from 'socket.io-client';

const abortControllers = {};
let currentSocket = null;
let currentRoom = {};

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000;
const cacheableEndpoints = [
  'admin/searchMission',
  'admin/getTempWorkerPostulateMission',
  'admin/getMissionDetailsById',
  'admin/getTempWorkerInviteMission',
];

const generateCacheKey = (url, data) => {
  const dataString = JSON.stringify(data || {});
  return `${url}|${dataString}`;
};

const updateCacheEntry = (url, data, newData) => {
  const cacheKey = generateCacheKey(url, data);
  if (cache[cacheKey]) {
    cache[cacheKey] = {
      data: newData,
      timestamp: Date.now(),
    };
  }
};

const isCacheValid = (cacheEntry) => {
  if (!cacheEntry) return false;
  return Date.now() - cacheEntry.timestamp < CACHE_DURATION;
};

const connectSocketWithToken = async () => {
  try {
    const token = localStorage.getItem('xsrfToken');
    if (token) {
      const decodedToken = jwtDecode(token);

      const newRoom = {
        userId: decodedToken.id || '',
      };

      // Vérifier si le socket actuel est connecté à la bonne salle
      if (
        currentSocket &&
        currentSocket?.connected &&
        currentRoom.userId === newRoom.userId
      ) {
        console.log('Socket déjà connecté à la bonne salle');
        return currentSocket;
      }

      // Si le socket existe mais n'est pas connecté à la bonne salle, le déconnecter
      if (currentSocket && !currentSocket?.connected) {
        console.log('Déconnexion du socket actuel');
        currentSocket.disconnect();
      }

      const socket = io(process.env.REACT_APP_API_URL_SOCKET, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
        transports: ['websocket'],
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
        },
      });

      // Attendre la connexion du socket
      await new Promise((resolve, reject) => {
        socket.on('connect', async () => {
          try {
            await socket.emit('joinRoom', newRoom);
            resolve(); // Résoudre la promesse une fois terminé
          } catch (error) {
            reject(error); // Rejeter en cas d'erreur
          }
        });

        // Gestion d'erreur si le socket échoue à se connecter
        socket.on('connect_error', (err) => {
          reject(new Error(`Erreur de connexion : ${err.message}`));
        });
      });

      currentSocket = socket;
      currentRoom = newRoom;
      return socket;
    }
    return null;
  } catch (error) {
    console.error('Erreur lors de la connexion Socket.IO :', error);
    throw error; // Relancer l'erreur si nécessaire
  }
};

const disconnectSocket = () => {
  if (currentSocket) {
    currentSocket.disconnect();
    currentSocket = null;
    currentRoom = {};
    console.log('Socket déconnecté');
  }
};

const renewTokenIfNeededAndConnectSocket = async () => {
  try {
    return await connectSocketWithToken();
  } catch (error) {
    console.error(
      'Erreur lors du renouvellement du token et de la connexion Socket.IO :',
      error
    );
  }
};

const getAbortController = (key) => {
  if (abortControllers[key]) {
    abortControllers[key].abort();
  }
  abortControllers[key] = new AbortController();
  return abortControllers[key];
};

const makeRequest = async (
  url,
  method,
  data,
  resolve,
  reject,
  options = {}
) => {
  const { skipCache = false } = options;

  try {
    const controller = getAbortController(url);
    const isCacheable = cacheableEndpoints.some((endpoint) =>
      url.startsWith(endpoint)
    );
    const cacheKey = isCacheable ? generateCacheKey(url, data) : null;

    if (
      isCacheable &&
      cacheKey &&
      !skipCache &&
      cache[cacheKey] &&
      isCacheValid(cache[cacheKey])
    ) {
      resolve(cache[cacheKey].data);
      return;
    }

    const requestConfig = {
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      withCredentials: true,
      signal: controller.signal,
    };

    if (method.toLowerCase() === 'get') {
      requestConfig.params = data;
    } else {
      requestConfig.data = data;
    }

    const response = await axios(requestConfig);

    if (response.data.errors) {
      reject(response.data.errors);
    } else {
      if (isCacheable && cacheKey) {
        cache[cacheKey] = {
          data: response.data,
          timestamp: Date.now(),
        };
      }
      resolve(response.data);
    }
  } catch (error) {
    reject(error);
  }
};

const cleanUpCache = () => {
  const now = Date.now();
  Object.keys(cache).forEach((key) => {
    if (now - cache[key].timestamp > CACHE_DURATION) {
      delete cache[key];
    }
  });
};

setInterval(cleanUpCache, 60 * 1000);

connectSocketWithToken();

const ApiDatabase = {
  getUserProfile: (payload, resolve, reject, options = {}) =>
    makeRequest('user/info', 'post', payload, resolve, reject, options),
  getTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest('tempWorker/info', 'post', payload, resolve, reject, options),
  postRoleUser: (payload, resolve, reject, options = {}) =>
    makeRequest('user/role', 'post', payload, resolve, reject, options),
  postPasswordRefresh: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'user/update/password',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  postEmailRefresh: (payload, resolve, reject, options = {}) =>
    makeRequest('user/update/email', 'put', payload, resolve, reject, options),
  postResendEmailToken: (payload, resolve, reject, options = {}) =>
    makeRequest('resend/tokenMail', 'post', payload, resolve, reject, options),
  postCreateTokenMail: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'resend/createToken',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getListLanguages: (resolve, reject, options = {}) =>
    makeRequest('language', 'get', {}, resolve, reject, options),
  postUserLanguage: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/create/language',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  deleteUserLanguage: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/languages',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  getListTools: (resolve, reject, options = {}) =>
    makeRequest('tools', 'get', {}, resolve, reject, options),
  postUserTool: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/create/tools',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postUserToolDelete: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/tools',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  deleteUserCV: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/cv',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  getInfoMission: (payload, resolve, reject, options = {}) =>
    makeRequest(`mission/info`, 'get', payload, resolve, reject, options),
  postCandidate: (payload, resolve, reject, options = {}) =>
    makeRequest('mission/addUser', 'post', payload, resolve, reject, options),
  postVerifyUser: (payload, resolve, reject, options = {}) =>
    makeRequest('mission/verify', 'post', payload, resolve, reject, options),
  deleteUserMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'mission/deleteUser',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  getPathFile: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `upload/getSingleFile/${payload.id}`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postCreateExp: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/create/experience',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  putUpdateExp: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/update/experience',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  postDeleteExp: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/experiences',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  getAllLicence: (resolve, reject, options = {}) =>
    makeRequest('licence', 'get', {}, resolve, reject, options),
  postDeleteLicence: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/licence',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  postDeleteFormation: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/delete/training',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdateIban: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'tempWorker/update/bank',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdatePhone: (payload, resolve, reject, options = {}) =>
    makeRequest('user/update/phone', 'put', payload, resolve, reject, options),
  postUpdateAddress: (payload, resolve, reject, options = {}) =>
    makeRequest('address/add', 'post', payload, resolve, reject, options),
  postUpdateAddressTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest('admin/address/add', 'post', payload, resolve, reject, options),
  getAddress: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `address/${payload.id}`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getTimeSheets: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/timeSheets/allInfo',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getTimeSheetInfo: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `timeSheets/${payload.id}`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getTimeSheetDay: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `timeSheets/getTimeSheetDay/${payload.id}`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdateDay: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'timeSheets/updateDayAdmin',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getAllUsersWithIdentityFilesToComplete: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      'admin/tempWorkersWithIdentityFilesToComplete',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getUserWithIdentityFilesToComplete: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      'admin/tempWorkerWithIdentityFilesToComplete',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postRefuseTempWorkerFileAdmin: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/refuseTempWorkerFileAdmin',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postAcceptTempWorkerFileAdmin: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/acceptTempWorkerFileAdmin',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getTempWorkerById: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `tempWorker/${payload.adminTempWorkerId}`,
      'get',
      {},
      resolve,
      reject,
      options
    ),
  getTempWorkerByIdUser: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `tempWorker/tempWorkerByIdUser/${payload.idUser}`,
      'get',
      {},
      resolve,
      reject,
      options
    ),
  getUserById: (payload, resolve, reject, options = {}) =>
    makeRequest(`user/${payload.id_user}`, 'get', {}, resolve, reject, options),
  postAcceptTempWorkerFileIdentityAdmin: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      'admin/acceptTempWorkerFileIdentityAdmin',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postAcceptTempWorkerFileVisaAdmin: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/acceptTempWorkerFileVisaAdmin',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postAcceptTempWorkerFileVitalCardAdmin: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      'admin/acceptTempWorkerFileVitalCardAdmin',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getTimeSheetsBeSTT: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/getTimeSheetsBeSTT',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdateTimeSheet: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'timeSheets/updateCompany',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getCompanies: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getCompaniesList`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getCompanyInfo: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getCompanyInfo`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getMinimumWage: (resolve, reject, options = {}) =>
    makeRequest('admin/getMinimumWage', 'get', {}, resolve, reject, options),
  postCoefficient: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/postCoefficient',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  searchMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/searchMission',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getMissionDetailsById: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getMissionDetailsById`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  searchTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/searchTempWorker',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  modifyMissionPeriod: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'mission/modifyMissionPeriod',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  assignTempWorkerToMission: (payload, resolve, reject, options = {}) =>
    makeRequest('mission/assign', 'post', payload, resolve, reject, options),
  requestSignContract: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/requestSignContract',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getJobs: (payload, resolve, reject, options = {}) =>
    makeRequest(`hrFlowJobs/getJobs`, 'get', payload, resolve, reject, options),
  postJob: (payload, resolve, reject, options = {}) =>
    makeRequest('hrFlowJobs/addJob', 'post', payload, resolve, reject, options),
  putJob: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'hrFlowJobs/updateJob',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  patchJob: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'hrFlowJobs/deleteJob',
      'patch',
      payload,
      resolve,
      reject,
      options
    ),
  getTempWorkerPostulateMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/getTempWorkerPostulateMission',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  unassignMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/unassignMission',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  putDisplayCostEstimate: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/updateDisplayCostEstimate',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getDisplayCostEstimate: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getDisplayCostEstimate`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getScheduleByIdMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getScheduleByIdMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  linkCompanyToBigAccount: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/linkCompanyToBigAccount',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getBigAccounts: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/getBigAccounts',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  addBigAccount: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/addBigAccount',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getCompanyBigAccount: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getCompanyBigAccount`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  updScheduleByIdMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'mission/updScheduleByIdMission',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  deleteCompanyBigAccount: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/deleteCompanyBigAccount',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  deleteBigAccount: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/deleteBigAccount',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  requestGenerationContract: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/requestGenerationContract',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postAddCompany: (payload, resolve, reject, options = {}) =>
    makeRequest('company/add', 'post', payload, resolve, reject, options),
  getReasonRefusesDocuments: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/reasonRefusesDocuments`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getRatingTreatedTempWorkers: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/ratingTreatedTempWorkers`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getRatingUntreatedTempWorkers: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/ratingUntreatedTempWorkers`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getRatingTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/ratingTempWorker`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getRatingTempWorkerByMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/ratingTempWorkerByMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postTreatmentRatingTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/treatmentRatingTempWorker',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getNbRatingUntreatedTempWorkers: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/nbRatingUntreatedTempWorkers`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getReviewsByTempWorkerGroupByMissions: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      `admin/reviewsByTempWorkerGroupByMissions`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getReviewsByTempworkerByMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/reviewsByTempworkerByMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getTempWorkerInviteMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getTempWorkerInviteMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getNbReviewsTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/nbReviewsTempWorker`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getNationalities: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/nationalities`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getAllQualifications: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `qualification/getAllQualifications`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postQualification: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'qualification/postQualification',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  updateQualification: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'qualification/putQualification',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getQualificationByID: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `qualification/getQualificationByID`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  delQualification: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'qualification/delQualification',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  activateQualification: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'qualification/activateQualification',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  postExcludeAgency: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'vivier/postExcludeAgency',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getLocalisationPreferenceUser: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/localisationPreference`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  updLocalisationPreferenceUsers: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/localisationPreferenceUsers',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  deleteVivier: (payload, resolve, reject, options = {}) =>
    makeRequest('vivier/delete', 'post', payload, resolve, reject, options),
  deleteVivierUser: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'vivier/deleteCandidateVivier',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  updUserVivierPreferenceStatus: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'vivier/updUserVivierPreferenceStatus',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getCommentsByTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/commentsByTempWorker`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getNbCommentsByTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/nbCommentsByTempWorker`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postCommentTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/commentTempWorker',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  deleteCommentTempWorker: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/commentTempWorker',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  getVivierById: (payload, resolve, reject, options = {}) =>
    makeRequest(`vivier/vivierById`, 'get', payload, resolve, reject, options),
  updateUsersVivierStatus: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'vivier/updateUsersVivierStatus',
      'put',
      payload,
      resolve,
      reject,
      options
    ),
  getUserVivierArchiveReason: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/userVivierArchiveReason`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getUserViviers: (payload, resolve, reject, options = {}) =>
    makeRequest(`admin/userViviers`, 'get', payload, resolve, reject, options),
  getUserMissions: (payload, resolve, reject, options = {}) =>
    makeRequest(`admin/userMissions`, 'get', payload, resolve, reject, options),
  getCollectiveAgreement: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/collectiveAgreement`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  deleteAdminVivierFavorite: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/adminVivierFavorite',
      'delete',
      payload,
      resolve,
      reject,
      options
    ),
  searchCompanyInfosBySiret: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/searchCompanyInfosBySiret`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postAdminVivierFavorite: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/adminVivierFavorite',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  searchCompanyInfosByName: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/searchCompanyInfosByName`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getTimeSheetHoursVerified: (payload, resolve, reject, options = {}) =>
    makeRequest(
      'admin/getTimeSheetHoursVerified',
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getVivierListUsers: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `vivier/vivierListUser`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getVivierAllPage: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `vivier/vivierAllPage`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getVivierFiche: (payload, resolve, reject, options = {}) =>
    makeRequest(`vivier/vivierFiche`, 'get', payload, resolve, reject, options),
  getMissionVivier: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `vivier/missionVivier`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getLogVivier: (payload, resolve, reject, options = {}) =>
    makeRequest(`vivier/logVivier`, 'get', payload, resolve, reject, options),
  getVivierAll: (payload, resolve, reject, options = {}) =>
    makeRequest(`vivier/vivierAll`, 'get', payload, resolve, reject, options),
  postCancelMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/cancelMission`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postDeleteMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/deleteMission`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getInfoMissionCancelDelete: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `mission/infoMissionCancelDelete`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getDepartmentMissions: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/departmentMissions`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postTempWorkerAssignationRefused: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/tempWorkerAssignationRefused`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postDeleteContractHistory: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/deleteContractHistory`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getQualificationsActive: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `qualification/getQualificationsActive`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postMissionDetail: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postMissionDetail`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postViviersMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postViviersMission`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postViviersMissionNotification: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postViviersMissionNotification`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postViviersMissionNotificationGlobal: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      `admin/postViviersMissionNotificationGlobal`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getVivierNotificationMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getVivierNotificationMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getLastMissionNotificationGlobal: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getLastMissionNotificationGlobal`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getDescriptionCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getDescriptionCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postDescriptionCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postDescriptionCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdateCompanyOverview: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postUpdateCompanyOverview`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postAddCompanyMember: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postAddCompanyMember`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getCompanyEmployee: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getCompanyEmployee`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postEditCompanyMember: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postEditCompanyMember`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postDelCompanyMember: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postDelCompanyMember`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postSendMailLinkCompanyEmployee: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postSendMailLinkCompanyEmployee`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getHubSpotContactByEmail: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getHubSpotContactByEmail`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postNightHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postNightHoursCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getNightHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getNightHoursCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getOvertimesCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getOvertimesCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postOvertimesCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postOvertimesCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getSundayHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getSundayHoursCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postSundayHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postSundayHoursCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getHolidayHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getHolidayHoursCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postHolidayHoursCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postHolidayHoursCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getRestaurantTicketCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getRestaurantTicketCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getRestaurantTicketModalityListCompany: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      `admin/getRestaurantTicketModalityListCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postRestaurantTicketCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postRestaurantTicketCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getBonusListCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getBonusListCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  getBonusUnitListCompany: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getBonusUnitListCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postAddBonusesAndAllowances: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postAddBonusesAndAllowances`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postUpdBonusesAndAllowances: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postUpdBonusesAndAllowances`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getBonusAndAllowance: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getBonusAndAllowance`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postDelBonusesAndAllowances: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postDelBonusesAndAllowances`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getBonusesAndAllowancesOtherCompany: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      `admin/getBonusesAndAllowancesOtherCompany`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postBonusesAndAllowancesOtherCompany: (
    payload,
    resolve,
    reject,
    options = {}
  ) =>
    makeRequest(
      `admin/postBonusesAndAllowancesOtherCompany`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  getEmployeesToAssignMission: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/getEmployeesToAssignMission`,
      'get',
      payload,
      resolve,
      reject,
      options
    ),
  postMissionAdminEmployeeAssigned: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postMissionAdminEmployeeAssigned`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  postImportedTimeSheet: (payload, resolve, reject, options = {}) =>
    makeRequest(
      `admin/postImportedTimeSheet`,
      'post',
      payload,
      resolve,
      reject,
      options
    ),
  connectSocketWithToken,
  cache,
  cacheableEndpoints,
  generateCacheKey,
  updateCacheEntry,
};

export default ApiDatabase;
