const BadgeSquare = ({ type, children, ...props }) => {
  const body = {
    0: `bg-red-100`,
    3: `bg-red-100`,
    2: ``,
    1: `bg-yellow-100`,
    4: `bg-yellow-100`,
    5: `bg-green-100`,
    6: `bg-green-100`,
    7: `bg-red-100`,
    8: `bg-red-100`,
    9: `bg-gray-100`,
    10: `bg-green-100`,
    11: `bg-red-100`,
  };
  const content = {
    0: `text-red-800`,
    3: `text-red-800`,
    2: ``,
    1: `text-yellow-800`,
    4: `text-yellow-800`,
    5: `text-green-800`,
    6: `text-green-800`,
    7: `text-red-800`,
    8: `text-red-800`,
    9: `text-gray-800`,
    10: `text-green-800`,
    11: `text-red-800`,
  };
  const text = {
    0: `À importer`,
    3: `Refusé`,
    2: ``,
    1: `Vérification en cours`,
    4: `En attente de vérification`,
    5: `Validé`,
    6: `Lu`,
    7: `Non Lu`,
    8: `À supprimer`,
    9: `Non signé`,
    10: `Signé`,
    11: `Urgent`,
  };

  if (
    body[type] === undefined &&
    content[type] === undefined &&
    text[type] === undefined
  )
    return null;

  return (
    <>
      <div className={`flex justify-center items-center rounded ${body[type]}`}>
        <div className={`px-2 py-0.5 ${content[type]}`}>{text[type]}</div>
      </div>
    </>
  );
};

export default BadgeSquare;
