import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import removeAccents from 'remove-accents';
import ApiDatabase from 'server';
import { Loading2, SearchLoop } from '../../../../../assets/icons';
import { dateDiff } from '../../../../../helper/helper';
import UseContext from '../../../../../hooks/useContext';
import Banner from '../../../../banner/banner';
import Button from '../../../../button';
import Divider from '../../../../divider/divider';
import EmptyState from '../../../../EmptyState/EmptyState';
import Pagination from '../../../../pagination/pagination';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ModalMissionNotify = () => {
  const { toggle } = useModal();
  const { objID, setObjID } = UseContext();
  const [selectedColumn, setSelectedColumn] = useState([
    'wish',
    'test',
    'valid',
  ]);
  const [userGroupedByVivierStatus, setUserGroupedByVivierStatus] = useState(
    {}
  );
  const [search, setSearch] = useState('');
  const [listAddVivierMission, setListAddVivierMission] = useState([]);
  const [listAddVivierMissionToDisplay, setListAddVivierMissionToDisplay] =
    useState([]);
  const [totalUsersAvailable, setTotalUsersAvailable] = useState([]);
  const [sendNotifLoading, setSendNotifLoading] = useState(false);
  const [searchVivierWaiting, setSearchVivierWaiting] = useState([]);
  const [countTotal, setCountTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(20);
  const [lastNotificationMission, setLastNotificationMission] = useState('');

  const token = localStorage.getItem('xsrfToken');

  const kanbanColumns = [
    { name: 'wish', title: 'Souhait', icon: '🧞' },
    { name: 'test', title: 'Test', icon: '⏳' },
    { name: 'valid', title: 'Validé', icon: '✅' },
  ];

  const vivierAll = async () => {
    setSearchVivierWaiting(true);
    ApiDatabase.postViviersMission(
      { token, idMission: objID, vivierStatus: selectedColumn },
      (data) => {
        setListAddVivierMission(data.viviers || []);
        setSearchVivierWaiting(false);
      },
      (err) => {
        setListAddVivierMission([]);
        setSearchVivierWaiting(false);
      }
    );
  };

  const getLastVivierNotificationMission = async () => {
    ApiDatabase.getVivierNotificationMission(
      { token, idMission: objID },
      (data) => {
        setLastNotificationMission(dateDiff(data.lastNotificationDate));
      },
      (err) => {
        setLastNotificationMission('');
      }
    );
  };

  useEffect(() => {
    vivierAll();
    getLastVivierNotificationMission();
  }, [token, objID, selectedColumn]);

  const handleSubmit = () => {
    setSendNotifLoading(true);
    ApiDatabase.postViviersMissionNotification(
      {
        token,
        idMission: objID,
        vivierStatus: selectedColumn,
        viviersSelected: listAddVivierMission
          .filter((vivier) => vivier.checked === true)
          .map((vivier) => vivier._id),
      },
      (data) => {
        toast.success('La notification a été envoyée.');
        setSendNotifLoading(false);
        setObjID('');
        toggle();
      },
      (err) => {
        toast.error(err.response?.data?.message || 'Une erreur est survenue.');
        setSendNotifLoading(false);
      }
    );
  };

  useEffect(() => {
    if (listAddVivierMission) {
      let totalUsers = [];
      for (let i = 0; i < listAddVivierMission.length; i++) {
        if (listAddVivierMission[i].checked) {
          totalUsers.push(...listAddVivierMission[i].users_available);
        }
      }

      totalUsers = [...new Set(totalUsers)];
      setTotalUsersAvailable(totalUsers);
    }
  }, [listAddVivierMission]);

  useEffect(() => {
    if (listAddVivierMission.length > 0) {
      setSearchVivierWaiting(true);

      let sortedResults = listAddVivierMission;
      if (removeAccents(search.toLowerCase()) !== '') {
        const searchTerms = search
          .split(' ')
          .map((term) => removeAccents(term.toLowerCase()));

        const options = {
          keys: ['name'],
          includeScore: true,
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          getFn: (obj, path) => {
            const value = Fuse.config.getFn(obj, path);
            if (value == null) {
              return '';
            }
            return removeAccents(value.toString().toLowerCase());
          },
        };

        const fuse = new Fuse(listAddVivierMission, options);
        const combinedResults = {};

        searchTerms.forEach((term) => {
          const result = fuse.search(term);
          result.forEach(({ item, score }) => {
            const indexSearch = `${item._id}${item.search}`;
            if (combinedResults[indexSearch]) {
              combinedResults[indexSearch].score -= score;
            } else {
              combinedResults[indexSearch] = { item, score };
            }
          });
        });

        sortedResults = Object.values(combinedResults)
          .sort((a, b) => a.score - b.score)
          .map((result) => result.item);
      }

      setCountTotal(sortedResults.length);
      setTotalPages(Math.ceil(sortedResults.length / limitElement));
      const start = (currentPage - 1) * limitElement;
      const end = start + limitElement;

      const paginatedResults = sortedResults.slice(start, end);

      setListAddVivierMissionToDisplay(paginatedResults);

      setSearchVivierWaiting(false);
    }
  }, [search, listAddVivierMission, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [listAddVivierMission, selectedColumn]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Notifier la mission
      </Modalheader>
      <ModalBody>
        <div className={'flex flex-col gap-8 text-sm'}>
          {lastNotificationMission && (
            <Banner type={'info'}>
              La mission a été notifiée il y a{' '}
              {lastNotificationMission.day > 0
                ? `${lastNotificationMission.day} jours`
                : lastNotificationMission.hour > 0
                ? `${lastNotificationMission.hour} heures`
                : lastNotificationMission.min > 0
                ? `${lastNotificationMission.min} minutes`
                : lastNotificationMission.sec > 0
                ? `${lastNotificationMission.sec} secondes`
                : ''}
            </Banner>
          )}
          <div className={'flex flex-col gap-4'}>
            <div className={'text-sm'}>1. Sélectionner l'audience</div>
            <div className={'flex gap-4'}>
              {kanbanColumns.map((kanbanColumn, index) => (
                <div
                  key={index}
                  className={`rounded p-3 w-full h-full cursor-pointer border ${
                    selectedColumn.includes(kanbanColumn.name)
                      ? 'border-yellow-500 bg-yellow-50'
                      : 'border-gray-100 bg-gray-100'
                  }`}
                  onClick={() => {
                    if (selectedColumn.includes(kanbanColumn.name)) {
                      setSelectedColumn(
                        selectedColumn.filter(
                          (item) => item !== kanbanColumn.name
                        )
                      );
                    } else {
                      setSelectedColumn([...selectedColumn, kanbanColumn.name]);
                    }
                  }}
                >
                  <div className={'flex justify-between items-center gap-1'}>
                    <div className={'flex gap-2'}>
                      <div className={'my-auto text-xl'}>
                        {kanbanColumn.icon}
                      </div>
                      <div className={'my-auto'}>{kanbanColumn.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Divider />
          <div className={'flex flex-col gap-4'}>
            <div className={'text-sm'}>2. Sélectionner les viviers</div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex items-center gap-x-4">
                <div className="relative text-gray-500 w-full">
                  <input
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="w-full h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md text-sm"
                    type="text"
                    placeholder="Recherche..."
                    required
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                    <SearchLoop wh={18} color={'#666666'} />
                  </div>
                </div>
              </div>
              {searchVivierWaiting ? (
                <div
                  className={`flex flex-col gap-10 justify-center items-center h-40`}
                >
                  <div className={'animate-spin'}>
                    <Loading2 wh={60} color={'#FF9600'} />
                  </div>
                </div>
              ) : (
                <>
                  {listAddVivierMissionToDisplay?.length > 0 &&
                  selectedColumn.length > 0 ? (
                    <>
                      <div className="overflow-auto">
                        {!searchVivierWaiting &&
                          listAddVivierMissionToDisplay?.length > 0 &&
                          listAddVivierMissionToDisplay?.map((vivier, key) => (
                            <>
                              <div className="flex flex-col py-2" key={key}>
                                <div
                                  className={
                                    'py-2 flex items-center justify-between cursor-pointer'
                                  }
                                >
                                  <label className="flex items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className={
                                        'w-4 h-4 accent-yellow-400 my-auto'
                                      }
                                      checked={vivier.checked}
                                      onChange={() => {
                                        setListAddVivierMission((prevData) =>
                                          prevData.map((item) =>
                                            item._id === vivier._id
                                              ? {
                                                  ...item,
                                                  checked: !item.checked,
                                                }
                                              : item
                                          )
                                        );
                                      }}
                                    />
                                    <div className="flex flex-col pl-4">
                                      {vivier.name}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  ) : (
                    <EmptyState type={'mission_search'} />
                  )}
                </>
              )}
            </div>
            {currentPage && totalPages > 1 && setCurrentPage ? (
              <div className={''}>
                <Pagination
                  currentPage={currentPage}
                  countPerPage={limitElement}
                  countTotal={countTotal}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'flex w-full justify-between items-center'}>
          <div className={'flex gap-2 items-center text-sm'}>
            {listAddVivierMission.length > 0 && (
              <>
                <div></div>
                <div>
                  {totalUsersAvailable.length > 1
                    ? `${totalUsersAvailable.length} profils disponibles`
                    : `${totalUsersAvailable.length} profil disponible`}
                </div>
              </>
            )}
          </div>
          {sendNotifLoading ? (
            <div className={'flex gap-2'}>
              <Button size={'LG'} color={'DISABLED'}>
                Annuler
              </Button>
              <Button size={'LG'} color={'DISABLED'}>
                Confirmer ...
              </Button>
            </div>
          ) : (
            <div className={'flex gap-2'}>
              <Button onClick={toggle} size={'LG'} color={'SECONDARY'}>
                Annuler
              </Button>
              <Button onClick={handleSubmit} size={'LG'}>
                Confirmer
              </Button>
            </div>
          )}
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalMissionNotify;
