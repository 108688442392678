import { CrossIcon, DocumentIcon, Loading2, Plus, Signer } from 'assets/icons';
import UseModal from 'components/modal/useModal';
import useSlideOver from 'components/SlideOver/useSlideOver';
import { dateWordShort } from 'helper/helper';
import UseContext from 'hooks/useContext';
import useImportedTimeSheet from 'hooks/useImportedTimeSheet';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Tbodyhours = ({ champsBody }) => {
  const { toggleSlideOver } = useSlideOver();
  const { setObjID, roleUser, setContent, updatedTimesheet } = UseContext();
  const { toggle } = UseModal();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('xsrfToken');
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState(champsBody);
  const [waitingStates, setWaitingStates] = useState({});

  const { toggleImported, timeSheetVerified } = useImportedTimeSheet();

  useEffect(() => {
    setData(champsBody);
  }, [champsBody]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      setSelectedId(id);
    }
  }, [location]);

  const handleModal = (e, i, item) => {
    e.preventDefault();
    setObjID(i);
    setContent({
      title: item.title,
      idMission: item.id_mission_reel,
      nameCompany: item.name_commercial,
    });

    const currentUrl = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set('id', i);
    window.history.replaceState({}, '', `${currentUrl}?${params.toString()}`);

    navigate(`../ficheHour/${i}`);
  };

  const handleModalConsult = (e, i, item) => {
    e.preventDefault();
    setObjID(i);
    setContent({
      title: item.title,
      idMission: item.id_mission_reel,
      nameCompany: item.name_commercial,
    });

    const currentUrl = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set('id', i);
    window.history.replaceState({}, '', `${currentUrl}?${params.toString()}`);

    navigate(`../ficheHour/${i}`);
  };

  const openTempWorkerInfo = (id) => {
    setObjID(id);
    toggleSlideOver('get_temp_worker');
  };

  // Remplacement de la fonction importBestt par handleToggleImported
  const handleToggleImported = (isImported, idTimeSheet) => {
    setWaitingStates((prev) => ({ ...prev, [idTimeSheet]: true }));
    toggleImported(isImported, idTimeSheet, (imported) => {
      setData((prevData) =>
        prevData.map((item) =>
          item._id === idTimeSheet
            ? { ...item, is_imported_bestt: imported }
            : item
        )
      );
      setWaitingStates((prev) => ({ ...prev, [idTimeSheet]: false }));
    });
  };

  useEffect(() => {
    if (updatedTimesheet) {
      setData((prevData) =>
        prevData.map((item) =>
          updatedTimesheet[item._id] !== undefined
            ? { ...item, is_imported_bestt: updatedTimesheet[item._id] }
            : item
        )
      );
    }
  }, [updatedTimesheet]);

  return (
    <>
      {data?.map((item, key) => {
        const rowClass =
          selectedId === item._id ? 'bg-yellow-50' : 'hover:bg-gray-50';

        return (
          <tr
            key={key}
            className={`border-b border-gray-100 ${rowClass} tr-time-sheet`}
          >
            <td className="py-5 px-2">{item.id_time_sheet}</td>
            <td className="py-5 px-2">{item.name_commercial}</td>
            <td className="py-5 px-2">{item.title}</td>
            <td className="py-5 px-2">{item.id_mission_reel}</td>
            <td
              className={`py-5 px-2 cursor-pointer ${rowClass}`}
              onClick={() => openTempWorkerInfo(item.id_user)}
            >
              {item.firstname} {item.lastname}
            </td>
            <td className="py-5 px-2">
              {dateWordShort(item.periode_start)} {'>'}{' '}
              {dateWordShort(item.periode_end)}
            </td>
            {timeSheetVerified && (
              <td className="py-5 px-2">
                <div className="flex justify-center tr-imported-time-sheet">
                  {waitingStates[item._id] ? (
                    <div className="animate-spin">
                      <Loading2 wh={20} color={'#6B7280'} />
                    </div>
                  ) : (
                    <>
                      {item.is_imported_bestt ? (
                        <div className="flex items-center gap-1 border border-green-200 rounded bg-green-50 text-green-700 py-0.5 px-2">
                          <div>Importé</div>
                          <div
                            className="cursor-pointer tr-button-imported-time-sheet"
                            onClick={() =>
                              handleToggleImported(false, item._id)
                            }
                          >
                            <CrossIcon wh={16} color="#047857" />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="rounded outline-dashed outline-gray-300 w-fit cursor-pointer tr-button-to-import-time-sheet"
                          onClick={() => handleToggleImported(true, item._id)}
                        >
                          <Plus wh="20" color="#6B7280" />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </td>
            )}
            {item.validated !== 0 && (
              <td className="py-5 px-2">
                <div className="flex justify-center cursor-pointer">
                  {item.validated === 1 || item.validated === 2 ? (
                    <span onClick={(e) => handleModal(e, item._id, item)}>
                      <Signer wh={24} />
                    </span>
                  ) : (
                    <span
                      onClick={(e) => handleModalConsult(e, item._id, item)}
                    >
                      <DocumentIcon wh={24} color="#111827" />
                    </span>
                  )}
                </div>
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};

export default Tbodyhours;
