import { createPopper } from '@popperjs/core';
import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import removeAccents from 'remove-accents';
import {
  Check,
  ChevronDown,
  CrossIcon,
  Import,
  NoShowPassword,
  ShowPassword,
  WarningCircle,
} from '../../assets/icons/index';

const StyledInput = ({
  label,
  type,
  unit,
  list,
  fileName,
  fileAccept,
  fileSize,
  placeholder,
  value,
  checked,
  onChange,
  error,
  imgFront,
  isSearchable,
  isSecondary,
  isOptional,
  isDisabled,
  step,
  className,
  classNameValue,
  additionalClassName,
  classNameDropDown,
  additionalClassNameDropDown,
  showChevron = true,
  showCheck = true,
}) => {
  const [typeInputChange, setTypeInputChange] = useState(type || 'text');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState(list);
  const selectMultipleRef = useRef();
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (
        selectMultipleRef.current &&
        !selectMultipleRef.current.contains(e.target)
      ) {
        setIsSelectOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [selectMultipleRef]);

  useEffect(() => {
    if (isSearchable && search && list.length > 0) {
      const searchTerms = search
        .split(' ')
        .map((term) => removeAccents(term.toLowerCase()));

      const options = {
        keys: ['label'],
        includeScore: true,
        threshold: 0.3,
        distance: 100,
        shouldSort: true,
        getFn: (obj, path) => {
          const value = Fuse.config.getFn(obj, path);
          if (value == null) {
            return '';
          }
          return removeAccents(value.toString().toLowerCase());
        },
      };

      const fuse = new Fuse(list, options);
      const combinedResults = {};

      searchTerms.forEach((term) => {
        const result = fuse.search(term);
        result.forEach(({ item, score }) => {
          if (combinedResults[item.value]) {
            combinedResults[item.value].score -= score;
          } else {
            combinedResults[item.value] = { item, score };
          }
        });
      });

      const sortedResults = Object.values(combinedResults).sort(
        (a, b) => a.score - b.score
      );

      setFilteredList(sortedResults.map((result) => result.item));
    } else {
      setFilteredList(list);
    }
  }, [search, list, isSearchable]);

  const deleteAllChecked = () => {
    onChange(list.map((item) => ({ ...item, checked: false })));
  };

  const handleKeyDown = (e) => {
    if (typeInputChange === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isSelectOpen) {
      createPopper(buttonRef.current, dropdownRef.current, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start'],
            },
          },
        ],
      });
    }
  }, [isSelectOpen]);

  return (
    <>
      {type === 'textarea' && (
        <label className={'flex flex-col gap-1'}>
          {label && (
            <div className={'text-gray-500 flex gap-1'}>
              {label}
              {isOptional && <div className="text-gray-400"> - Optionnel</div>}
            </div>
          )}
          <div className={'relative'}>
            <textarea
              cols="30"
              rows="5"
              className={`w-full border rounded-md p-2 ${
                isDisabled ? 'bg-gray-100' : ''
              }`}
              value={value}
              maxLength={500}
              minLength={3}
              placeholder={placeholder}
              onChange={onChange}
              disabled={isDisabled}
            />
          </div>
          {error && (
            <div className={'text-red-500 flex items-center gap-1 text-xs'}>
              <WarningCircle wh={16} color={'#EF4444'} />
              {error}
            </div>
          )}
        </label>
      )}
      {(type === 'text' ||
        type === 'password' ||
        type === 'number' ||
        type === 'time' ||
        type === 'email') && (
        <label className={'flex flex-col gap-1'}>
          {label && (
            <div className={'text-gray-500 flex gap-1'}>
              {label}
              {isOptional && <div className="text-gray-400"> - Optionnel</div>}
            </div>
          )}
          <div className={'relative'}>
            {imgFront ? (
              <div
                className={
                  'absolute left-0 top-1/2 transform -translate-y-1/2 p-1.5'
                }
              >
                {imgFront}
              </div>
            ) : null}
            <input
              type={typeInputChange}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              className={`w-full border rounded-md p-2 ${
                type === 'password' ? 'pr-7' : ''
              } ${
                type === 'number'
                  ? '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                  : ''
              } ${unit ? 'pr-20' : ''} ${imgFront ? 'pl-7' : ''} ${
                isDisabled ? 'bg-gray-100' : ''
              }`}
              disabled={isDisabled}
              step={step}
              {...(type === 'number' && {
                pattern: '[0-9]*',
                inputMode: 'numeric',
              })}
              {...(type === 'email' && { inputMode: 'email' })}
            />
            {type === 'password' && (
              <>
                <div
                  className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                  onClick={() => {
                    if (typeInputChange === 'password')
                      setTypeInputChange('text');
                    if (typeInputChange === 'text')
                      setTypeInputChange('password');
                  }}
                >
                  {typeInputChange === 'text' ? (
                    <NoShowPassword wh={18} color={'#666666'} strokeW={2} />
                  ) : (
                    <ShowPassword wh={18} color={'#666666'} strokeW={2} />
                  )}
                </div>
              </>
            )}
            {unit && (
              <div
                className={'absolute inset-y-0 right-0 flex items-center pr-3'}
              >
                {unit}
              </div>
            )}
          </div>
          {error && (
            <div className={'text-red-500 flex items-center gap-1 text-xs'}>
              <WarningCircle wh={16} color={'#EF4444'} />
              {error}
            </div>
          )}
        </label>
      )}
      {(type === 'checkbox' || type === 'checkbox-round') && (
        <label
          className={`flex gap-2 items-center relative cursor-pointer justify-between w-full ${
            isDisabled ? 'bg-gray-100' : ''
          }`}
        >
          <div className={'flex gap-2 items-center relative'}>
            <input
              type={'checkbox'}
              placeholder={placeholder}
              checked={checked}
              onChange={onChange}
              disabled={isDisabled}
              className={`${
                type === 'checkbox-round'
                  ? 'w-5 h-5 border rounded appearance-none checked:bg-workfully-700 checked:border-transparent'
                  : 'w-4 h-4 accent-workfully-700'
              } ${isSecondary ? 'hidden' : ''}`}
            />
            {type === 'checkbox-round' && (
              <div
                className={'absolute inset-0 flex items-center justify-center'}
              >
                <Check wh={14} color={'#FFFFFF'} />
              </div>
            )}
            {label && (
              <div className={'flex gap-1'}>
                {label}
                {isOptional && (
                  <div className="text-gray-400"> - Optionnel</div>
                )}
              </div>
            )}
          </div>
          {checked && isSecondary && (
            <div className={''}>
              <Check wh={16} color={'#374151'} />
            </div>
          )}
        </label>
      )}
      {type === 'radio' && (
        <label
          className={
            'flex gap-2 items-center relative cursor-pointer justify-between w-full'
          }
        >
          <div className={'flex gap-2 items-center relative'}>
            <input
              type={'radio'}
              placeholder={placeholder}
              checked={checked}
              onChange={onChange}
              className={`w-4 h-4 accent-workfully-700 ${
                isSecondary ? 'hidden' : ''
              }`}
            />
            {label && (
              <div className={'flex gap-1'}>
                {label}
                {isOptional && (
                  <div className="text-gray-400"> - Optionnel</div>
                )}
              </div>
            )}
          </div>
          <div className={''}>
            {checked && isSecondary && <Check wh={16} color={'#374151'} />}
          </div>
        </label>
      )}
      {type === 'file' && (
        <label className={'flex flex-col gap-1'}>
          <div className={'text-gray-500 flex gap-1'}>
            {label}
            {isOptional && <div className="text-gray-400"> - Optionnel</div>}
          </div>
          <label className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
            <div className="ft-sm text-gray-700 p-4 flex items-center justify-center gap-2 border-r border-gray-300">
              <Import color={'#374151'} wh={14} />
              Importer
            </div>
            <div className="ft-sm truncate overflow-hidden text-gray-400 flex items-center ml-3">
              {fileName ? fileName : 'Aucun fichier'}
            </div>
            <input
              type={type}
              onChange={onChange}
              accept={fileAccept.map((type) => type.name).join(', ')}
              className="hidden"
            />
          </label>
          <div className="ft-xs text-gray-400">
            {fileAccept.length > 1
              ? 'Formats acceptés : '
              : 'Format accepté : '}
            {fileAccept.map((type) => type.title).join(', ')} ({fileSize} max)
          </div>
          {error && (
            <div className={'text-red-500 flex items-center gap-1 text-xs'}>
              <WarningCircle wh={16} color={'#EF4444'} />
              {error}
            </div>
          )}
        </label>
      )}
      {type === 'select' && (
        <label className={'flex flex-col gap-1'}>
          {label && (
            <div className={'text-gray-500 flex gap-1'}>
              {label}
              {isOptional && <div className="text-gray-400"> - Optionnel</div>}
            </div>
          )}
          <div className={'relative'}>
            {imgFront ? (
              <div
                className={
                  'absolute left-0 top-1/2 transform -translate-y-1/2 p-1.5'
                }
              >
                {imgFront}
              </div>
            ) : null}
            <select
              value={value}
              onChange={onChange}
              className={`w-full border rounded-md p-2 ${
                imgFront ? 'pl-7' : ''
              }`}
            >
              {filteredList.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {error && (
              <div className={'text-red-500 flex items-center gap-1 text-xs'}>
                <WarningCircle wh={16} color={'#EF4444'} />
                {error}
              </div>
            )}
          </div>
        </label>
      )}
      {type === 'selectMultipleCheckbox' && (
        <div
          className={'flex flex-col gap-1 relative w-full cursor-pointer'}
          ref={selectMultipleRef}
        >
          {label && (
            <div className={'text-gray-500 flex gap-1'}>
              {label}
              {isOptional && <div className="text-gray-400"> - Optionnel</div>}
            </div>
          )}
          <div
            ref={buttonRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsSelectOpen(!isSelectOpen);
            }}
            onChange={onChange}
            className={`${
              className ?? 'w-full border rounded-md bg-white text-gray-900'
            } ${additionalClassName || ''}`}
          >
            <div className={'flex justify-between items-center w-full'}>
              <div className={'flex flex-1 min-w-0 items-center'}>
                {showCheck &&
                  filteredList &&
                  filteredList.some((item) => item.checked) && (
                    <div className={'flex justify-end px-1 py-1.5'}>
                      <Check wh={20} color={'#374151'} />
                    </div>
                  )}
                <div
                  className={`${classNameValue ?? 'truncate px-4 py-2'} ${
                    showChevron
                      ? filteredList &&
                        filteredList.some((item) => item.checked)
                        ? 'pl-1'
                        : 'pr-1'
                      : ''
                  }`}
                >
                  {value}
                </div>
              </div>
              {filteredList &&
                filteredList.every((item) => !item.checked && showChevron) && (
                  <div className={'flex justify-end px-1 py-2 pr-4'}>
                    <ChevronDown wh={20} color={'#374151'} />
                  </div>
                )}
              {filteredList && filteredList.some((item) => item.checked) && (
                <div
                  className={'flex justify-end px-1 py-2 pr-4'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteAllChecked();
                  }}
                >
                  <CrossIcon wh={18} color={'#374151'} />
                </div>
              )}
            </div>
          </div>
          {isSelectOpen && (
            <div
              ref={dropdownRef}
              className={
                'z-20 w-fit min-w-[230px] bg-white border rounded shadow-2xl py-4 px-3 max-h-[170px] overflow-y-auto dropDown'
              }
            >
              <div className={'flex flex-col gap-4'}>
                {isSearchable && (
                  <div>
                    <StyledInput
                      type={'text'}
                      placeholder={'Rechercher...'}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div
                  className={`${classNameDropDown ?? 'flex flex-col gap-4'} ${
                    additionalClassNameDropDown || ''
                  }`}
                >
                  {filteredList.map((item, index) => (
                    <div key={index} className="flex gap-1 dropDown-item">
                      <StyledInput
                        label={item.label}
                        type={'checkbox'}
                        isSecondary
                        checked={item.checked}
                        onChange={(e) => {
                          onChange(
                            list.map((i) =>
                              i.label === item.label
                                ? { ...i, checked: e.target.checked }
                                : i
                            )
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className={'text-red-500 flex items-center gap-1 text-xs'}>
              <WarningCircle wh={16} color={'#EF4444'} />
              {error}
            </div>
          )}
        </div>
      )}
      {type === 'selectMultipleRadio' && (
        <div
          className={'flex flex-col gap-1 relative w-full cursor-pointer'}
          ref={selectMultipleRef}
        >
          {label && (
            <div className={'text-gray-500 flex gap-1'}>
              {label}
              {isOptional && <div className="text-gray-400"> - Optionnel</div>}
            </div>
          )}
          <div
            ref={buttonRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsSelectOpen(!isSelectOpen);
            }}
            onChange={onChange}
            className={`${
              className ?? 'w-full border rounded-md bg-white text-gray-900'
            } ${additionalClassName || ''}`}
          >
            <div className={'flex justify-between items-center w-full'}>
              <div className={'flex flex-1 min-w-0 items-center'}>
                {showCheck &&
                  filteredList &&
                  filteredList.some((item) => item.checked) && (
                    <div className={'flex justify-end px-1 py-1.5'}>
                      <Check wh={20} color={'#374151'} />
                    </div>
                  )}
                <div
                  className={`${classNameValue ?? 'truncate px-4 py-2'} ${
                    showChevron
                      ? filteredList?.some((item) => item.checked)
                        ? 'pl-1'
                        : 'pr-1'
                      : ''
                  }`}
                >
                  {value}
                </div>
              </div>
              {filteredList &&
                filteredList.every((item) => !item.checked && showChevron) && (
                  <div className={'flex justify-end px-4 py-2'}>
                    <ChevronDown wh={20} color={'#374151'} />
                  </div>
                )}
            </div>
          </div>
          {isSelectOpen && (
            <div
              ref={dropdownRef}
              className={
                'z-20 w-fit min-w-[230px] bg-white border rounded shadow-2xl py-4 px-3 max-h-[170px] overflow-y-auto dropDown'
              }
            >
              <div className={'flex flex-col gap-4'}>
                {isSearchable && (
                  <div>
                    <StyledInput
                      type={'text'}
                      placeholder={'Rechercher...'}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div
                  className={`${classNameDropDown ?? 'flex flex-col gap-4'} ${
                    additionalClassNameDropDown || ''
                  }`}
                >
                  {filteredList.map((item, index) => (
                    <div key={index} className="flex gap-1 dropDown-item">
                      <StyledInput
                        label={item.label}
                        type={'radio'}
                        isSecondary
                        checked={item.checked}
                        onChange={(e) => {
                          onChange(
                            list.map((i) =>
                              i.label === item.label
                                ? { ...i, checked: e.target.checked }
                                : { ...i, checked: false }
                            )
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className={'text-red-500 flex items-center gap-1 text-xs'}>
              <WarningCircle wh={16} color={'#EF4444'} />
              {error}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StyledInput;
