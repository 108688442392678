// MissionPeriod component: Manages the mission search filters, results display, and detail view.
import MissionDisplay from 'components/Mission/MissionDisplay';
import Badge from 'components/badge/badge';
import {
  Building,
  Calendar,
  PersonIcon,
  ReferenceIcon,
  SearchLoop,
  Signer,
} from '../../../assets/icons';
import EmptyState from '../../../components/EmptyState/EmptyState';
import Button from '../../../components/button';
import PageForm from '../../../components/pageForm/PageForm';
import StyledInput from '../../../components/styledInput/StyledInput';
import ThreeStateCheckbox from '../../../components/threeStateCheckbox/threeStateCheckbox';
import { generateColorByName } from '../../../helper/helper';
import useMissionPeriod from '../../../hooks/useMissionPeriod';

const MissionPeriod = () => {
  // Destructure values and actions from the custom hook
  const {
    search,
    setSearch,
    searchMissionResult,
    searchMissionResultWaiting,
    isMissionSearchAssigned,
    setIsMissionSearchAssigned,
    typeContract,
    updateTypeContract,
    departments,
    updateDepartment,
    missionStatus,
    updateMissionStatus,
    employees,
    employeesSearch,
    updateEmployee,
    updateEmployeeSearch,
    openCompanyInfo,
    openTempWorkerInfo,
    handleDeleteMission,
    handleCancelMission,
    handleSelectMission,
    selectedMissions,
    shouldShowTrashButton,
    updateEmployeeForMission,
    currentPage,
    setCurrentPage,
    totalPages,
    countPerPage,
    countTotal,
    editMission,
    setEditMission,
    hoveredCompanyItems,
    setHoveredCompanyItems,
    setSearchMissionResult,
  } = useMissionPeriod();

  return (
    <PageForm>
      <div className="p-3 flex flex-col w-full h-full gap-3 mission-container">
        {/* Filter and search bar */}
        <div className="flex justify-between items-center text-sm mission-container-header">
          <div className="flex items-center gap-2">
            {/* Checkbox for mission assignment filter */}
            <div className="border rounded py-2 px-4">
              <label className="flex items-center gap-2 cursor-pointer">
                <ThreeStateCheckbox
                  checked={isMissionSearchAssigned}
                  onChange={setIsMissionSearchAssigned}
                />
                <div>Mission attribuée</div>
              </label>
            </div>
            {/* Dropdown for contract types */}
            <div className="max-w-[155px]">
              <StyledInput
                type="selectMultipleCheckbox"
                value="Contrat"
                list={typeContract.map((contract) => ({
                  value: contract.type,
                  label: contract.name,
                  checked: contract.checked,
                }))}
                onChange={updateTypeContract}
              />
            </div>
            {/* Dropdown for departments */}
            <div className="max-w-[175px]">
              <StyledInput
                type="selectMultipleCheckbox"
                value="Département"
                list={departments.map((department) => ({
                  value: department.name,
                  label: department.name,
                  checked: department.checked,
                }))}
                onChange={updateDepartment}
              />
            </div>
            {/* Dropdown for mission status */}
            <div className="max-w-[180px]">
              <StyledInput
                type="selectMultipleRadio"
                value={
                  missionStatus.filter((status) => status.checked).length > 0
                    ? missionStatus
                        .filter((status) => status.checked)
                        .map((status) => status.name)
                        .join(', ')
                    : 'Status'
                }
                list={missionStatus.map((status) => ({
                  value: status.type,
                  label: status.name,
                  checked: status.checked,
                }))}
                onChange={updateMissionStatus}
              />
            </div>
            {/* Dropdown for employee assignment */}
            <div className="max-w-[180px]">
              <StyledInput
                type="selectMultipleCheckbox"
                value={
                  employeesSearch.filter((employee) => employee.checked)
                    .length > 0
                    ? employeesSearch
                        .filter((employee) => employee.checked)
                        .map(
                          (employee) =>
                            `${employee.firstname} ${employee.lastname}`
                        )
                        .join(', ')
                    : 'Assigné'
                }
                list={employeesSearch.map((employee) => ({
                  value: employee._id,
                  label: (
                    <div className="flex gap-1.5 items-center">
                      {employee._id !== 'unassign' && (
                        <div
                          className="rounded-full w-[24px] h-[24px] flex items-center justify-center text-[10px] text-white"
                          style={{
                            backgroundColor: generateColorByName(
                              (employee.firstname || '') +
                                (employee.lastname || '')
                            ),
                          }}
                        >
                          {`${
                            employee.firstname?.charAt(0).toUpperCase() || ''
                          }${employee.lastname?.charAt(0).toUpperCase() || ''}`}
                        </div>
                      )}
                      <div>
                        {employee.firstname} {employee.lastname}
                      </div>
                    </div>
                  ),
                  checked: employee.checked,
                }))}
                onChange={updateEmployeeSearch}
                additionalClassNameDropDown="text-gray-900"
              />
            </div>
          </div>
          {/* Global search input */}
          <div className="flex items-center">
            <div className="w-[245px]">
              <StyledInput
                type="text"
                placeholder="Rechercher..."
                imgFront={<SearchLoop wh={16} color="#6B7280" />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Results and details display section */}
        <div className="flex flex-1 w-full h-full gap-3 overflow-y-auto mission-container-body">
          {searchMissionResultWaiting ? (
            // Display loading state with animated placeholders
            <div className="flex-1 h-full w-[555px] max-w-[555px] animate-pulse">
              <div className="flex flex-col gap-4 max-h-full w-full h-full">
                <div className="flex flex-col gap-3 overflow-y-auto h-full">
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-1.5 border border-gray-100 rounded-lg p-4 cursor-pointer hover:bg-gray-50"
                    >
                      <div className="flex gap-1.5 justify-between items-center">
                        <div className="flex items-center gap-1 flex-1 min-w-0">
                          <div className="truncate">
                            <div className="h-4 bg-gray-200 rounded w-56"></div>
                          </div>
                          <div>
                            <div className="h-4 bg-gray-200 rounded w-8"></div>
                          </div>
                        </div>
                        <div className="flex gap-1 items-center text-sm">
                          <div className="px-1.5">
                            <div className="h-6 bg-gray-200 rounded w-6"></div>
                          </div>
                          <Button size="SMI" color="DEFAULT">
                            <div className="h-6 bg-gray-200 rounded w-6"></div>
                          </Button>
                          <Button size="SMI" color="DEFAULT">
                            <div className="h-6 bg-gray-200 rounded w-6"></div>
                          </Button>
                          <Button size="SMI" color="DEFAULT">
                            <div className="h-6 bg-gray-200 rounded w-6"></div>
                          </Button>
                        </div>
                      </div>
                      <div className="text-sm flex items-center justify-between gap-4">
                        <div className="flex flex-col gap-0.5 text-gray-700 w-1/2">
                          <div className="flex gap-1 items-center">
                            <div>
                              <Calendar wh={18} color="#374151" />
                            </div>
                            <div className="h-4 bg-gray-200 rounded w-56"></div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div>
                              <Building wh={18} color="#374151" />
                            </div>
                            <div className="flex gap-1">
                              <div className="h-4 bg-gray-200 rounded w-56"></div>
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div>
                              <Signer wh={18} color="#374151" />
                            </div>
                            <div className="h-4 bg-gray-200 rounded w-56"></div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div>
                              <ReferenceIcon wh={18} color="#374151" />
                            </div>
                            <div className="h-4 bg-gray-200 rounded w-56"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : searchMissionResult.length > 0 ? (
            // Display the mission results list and detail panel
            <MissionDisplay
              searchMissionResult={searchMissionResult}
              editMission={editMission}
              setEditMission={setEditMission}
              selectedMissions={selectedMissions}
              employees={employees}
              hoveredCompanyItems={hoveredCompanyItems}
              setHoveredCompanyItems={setHoveredCompanyItems}
              openCompanyInfo={openCompanyInfo}
              openTempWorkerInfo={openTempWorkerInfo}
              handleSelectMission={handleSelectMission}
              shouldShowTrashButton={shouldShowTrashButton}
              handleDeleteMission={handleDeleteMission}
              handleCancelMission={handleCancelMission}
              updateEmployeeForMission={updateEmployeeForMission}
              currentPage={currentPage}
              countPerPage={countPerPage}
              countTotal={countTotal}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              setSearchMissionResult={setSearchMissionResult}
            />
          ) : (
            // Display an empty state when no results are found
            <EmptyState type="mission_search" />
          )}

          {/* Display a fixed footer for bulk mission assignment if any mission is selected */}
          {selectedMissions.length > 0 && (
            <div className="fixed bottom-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white p-1.5 rounded">
              <div className="flex items-center text-sm h-10">
                <div className="flex gap-1 items-center py-2 px-3">
                  <div className="w-fit">
                    <Badge type="WARNING">{selectedMissions.length}</Badge>
                  </div>
                  sélectionnée{selectedMissions.length > 1 ? 's' : ''}
                </div>
                <div className="w-0.5 h-1/2 self-center bg-white" />
                <div className="flex gap-1 items-center">
                  <StyledInput
                    type="selectMultipleRadio"
                    value={
                      <div className="flex gap-1">
                        <PersonIcon wh={20} color="#FFF" />
                        <div className="text-white">Attribuer à</div>
                      </div>
                    }
                    list={employees.map((employee) => ({
                      value: employee._id,
                      label: (
                        <div className="flex gap-1.5 items-center">
                          {employee._id !== 'unassign' && (
                            <div
                              className="rounded-full w-[24px] h-[24px] flex items-center justify-center text-[10px] text-white"
                              style={{
                                backgroundColor: generateColorByName(
                                  (employee.firstname || '') +
                                    (employee.lastname || '')
                                ),
                              }}
                            >
                              {`${
                                employee.firstname?.charAt(0).toUpperCase() ||
                                ''
                              }${
                                employee.lastname?.charAt(0).toUpperCase() || ''
                              }`}
                            </div>
                          )}
                          <div>
                            {employee.firstname} {employee.lastname}
                          </div>
                        </div>
                      ),
                      checked: employee.checked,
                    }))}
                    className={'rounded hover:bg-gray-900'}
                    additionalClassNameDropDown={'text-gray-900'}
                    showChevron={false}
                    showCheck={false}
                    onChange={updateEmployee}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageForm>
  );
};

export default MissionPeriod;
