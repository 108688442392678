// MissionDisplay component: Renders the list of missions, pagination, and the mission detail view.
import {
  Building,
  Calendar,
  ExternalLink,
  LinkIcon,
  Plus,
  ReferenceIcon,
  Signer,
  TrashIcon,
} from 'assets/icons';
import BadgeSquare from 'components/badge/badgeSquare';
import Button from 'components/button';
import CopyToClipboard from 'components/copyToClipboard';
import EmptyState from 'components/EmptyState/EmptyState';
import Pagination from 'components/pagination/pagination';
import StyledInput from 'components/styledInput/StyledInput';
import TempWorkerMission from 'components/tempWorkerMission/tempWorkerMission';
import { dateWordShort, generateColorByName } from 'helper/helper';
import AdminMissionDetails from 'pages/pages/mission/adminMissionDetails';

const MissionDisplay = ({
  searchMissionResult,
  editMission,
  setEditMission,
  selectedMissions,
  employees,
  hoveredCompanyItems,
  setHoveredCompanyItems,
  openCompanyInfo,
  openTempWorkerInfo,
  handleSelectMission,
  shouldShowTrashButton,
  handleDeleteMission,
  handleCancelMission,
  updateEmployeeForMission,
  currentPage,
  countPerPage,
  countTotal,
  totalPages,
  setCurrentPage,
  setSearchMissionResult,
}) => {
  return (
    <>
      {/* Left side: Mission list and pagination */}
      <div className="flex-1 h-full w-[555px] mission-container-body-list">
        <div className="flex flex-col gap-4 max-h-full w-full h-full">
          <div className="flex flex-col gap-3 overflow-y-auto h-full">
            {searchMissionResult.map((mission, index) => (
              <div
                key={index}
                className={`flex flex-col gap-1.5 ${
                  editMission === mission.id_mission_real
                    ? 'border border-yellow-500'
                    : 'border border-gray-100'
                } rounded-lg p-4 cursor-pointer hover:bg-gray-50 group mission-container-body-list-item`}
                // Select the mission when clicked
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditMission(mission.id_mission_real);
                }}
              >
                <div className="flex gap-1.5 justify-between items-center">
                  {/* Checkbox for bulk mission selection; visible on hover */}
                  <div
                    className={`${
                      selectedMissions.includes(mission.id_mission_real)
                        ? 'block'
                        : 'hidden group-hover:block'
                    }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <StyledInput
                      type="checkbox"
                      checked={selectedMissions.includes(
                        mission.id_mission_real
                      )}
                      onChange={(e) =>
                        handleSelectMission(
                          mission.id_mission_real,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                  {/* Mission title and ID */}
                  <div className="flex items-center gap-1 flex-1 min-w-0">
                    <div className="truncate">{mission.title}</div>
                    <div>n°{mission.id_mission}</div>
                  </div>
                  {/* Action buttons and employee assignment display */}
                  <div className="flex gap-1.5 items-center text-xs mission-container-body-list-item-actions">
                    {mission.status !== 'cancel' && mission.is_urgent && (
                      <BadgeSquare type={11} />
                    )}
                    <div className="hidden group-hover:flex gap-1.5 items-center">
                      {/* Conditionally show delete/cancel button based on mission state */}
                      {shouldShowTrashButton(mission) === 'delete' && (
                        <Button
                          size="XS"
                          color="DEFAULT"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDeleteMission(mission.id_mission_real);
                          }}
                        >
                          <TrashIcon wh={16} color="#374151" />
                        </Button>
                      )}
                      {shouldShowTrashButton(mission) === 'cancel' && (
                        <Button
                          size="XS"
                          color="DEFAULT"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCancelMission(mission.id_mission_real);
                          }}
                        >
                          <TrashIcon wh={16} color="#374151" />
                        </Button>
                      )}
                      {/* Button to copy the mission URL to clipboard */}
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_INTERIM}ficheMissionSearch/${mission.id_mission_real}`}
                      >
                        <Button size="XS" color="DEFAULT">
                          <LinkIcon wh={16} color="#374151" />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    {/* Display count of accepted users or "Annulé" if cancelled */}
                    <div className="border rounded w-[24px] h-[24px] flex items-center justify-center">
                      {mission.status === 'cancel'
                        ? 'Annulé'
                        : mission.users
                        ? mission.users.filter((user) => user.refused === false)
                            .length
                        : 0}
                    </div>
                    {/* Employee assignment dropdown */}
                    <div
                      className="mission-container-body-list-item-actions-admin-employee-assignment"
                      onClick={(e) => {
                        const radioLabel = e.target.closest('label');
                        if (
                          !(
                            radioLabel &&
                            radioLabel.querySelector('input[type="radio"]')
                          )
                        ) {
                          e.preventDefault();
                        }
                        e.stopPropagation();
                      }}
                    >
                      {(() => {
                        const isAssigned = Boolean(
                          mission.admin_employee_assigned
                        );
                        const currentEmployee = isAssigned
                          ? employees.find(
                              (emp) =>
                                emp._id === mission.admin_employee_assigned
                            )
                          : null;
                        const valueElement =
                          isAssigned && currentEmployee ? (
                            <div
                              className="rounded-full w-[24px] h-[24px] flex items-center justify-center text-[10px] text-white"
                              style={{
                                backgroundColor: generateColorByName(
                                  `${currentEmployee.firstname}${currentEmployee.lastname}`
                                ),
                              }}
                            >
                              {currentEmployee.firstname
                                ?.charAt(0)
                                .toUpperCase()}
                              {currentEmployee.lastname
                                ?.charAt(0)
                                .toUpperCase()}
                            </div>
                          ) : (
                            <div className="outline-dashed outline-gray-300 rounded-full w-[24px] h-[24px] flex items-center justify-center">
                              <Plus wh={16} color="#374151" />
                            </div>
                          );
                        return (
                          <StyledInput
                            type="selectMultipleRadio"
                            value={valueElement}
                            list={employees.map((employee) => ({
                              value: employee._id,
                              label: (
                                <div className="flex gap-1.5 items-center">
                                  {employee._id !== 'unassign' && (
                                    <div
                                      className="rounded-full w-[24px] h-[24px] flex items-center justify-center text-[10px] text-white"
                                      style={{
                                        backgroundColor: generateColorByName(
                                          employee.firstname + employee.lastname
                                        ),
                                      }}
                                    >
                                      {`${
                                        employee.firstname
                                          ?.charAt(0)
                                          .toUpperCase() || ''
                                      }${
                                        employee.lastname
                                          ?.charAt(0)
                                          .toUpperCase() || ''
                                      }`}
                                    </div>
                                  )}
                                  <div>
                                    {employee.firstname} {employee.lastname}
                                  </div>
                                </div>
                              ),
                              checked: isAssigned
                                ? employee._id ===
                                  mission.admin_employee_assigned
                                : employee._id === 'unassign',
                            }))}
                            className={''}
                            classNameValue={''}
                            additionalClassNameDropDown="text-gray-900 text-sm"
                            showChevron={false}
                            showCheck={false}
                            onChange={(updatedEmployee) =>
                              updateEmployeeForMission(
                                mission.id_mission_real,
                                updatedEmployee
                              )
                            }
                          />
                        );
                      })()}
                    </div>
                  </div>
                </div>
                {/* Mission details: Date range, company info, contract, and reference */}
                <div className="text-sm flex items-center justify-between gap-4">
                  <div className="flex flex-col gap-0.5 text-gray-700 w-1/2">
                    <div className="flex gap-1 items-center">
                      <div>
                        <Calendar wh={18} color="#374151" />
                      </div>
                      {dateWordShort(mission.duration.datestart) +
                        ' > ' +
                        dateWordShort(mission.duration.dateend)}
                    </div>
                    <div className="flex gap-1 items-center">
                      <div>
                        <Building wh={18} color="#374151" />
                      </div>
                      <div
                        className="flex gap-1"
                        onMouseEnter={() =>
                          setHoveredCompanyItems((prev) => ({
                            ...prev,
                            [index + mission.company?.id_company]: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setHoveredCompanyItems((prev) => ({
                            ...prev,
                            [index + mission.company?.id_company]: false,
                          }))
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openCompanyInfo(mission.company?.id_company);
                        }}
                      >
                        {mission.company?.reason_social ===
                        mission.company?.name_commercial
                          ? mission.company?.reason_social
                          : `${mission.company?.reason_social} => ${mission.company?.name_commercial}`}
                        {mission.company?.id_bestt
                          ? ` n°${mission.company?.id_bestt}`
                          : ''}
                        {hoveredCompanyItems[
                          index + mission.company?.id_company
                        ] && (
                          <div>
                            <ExternalLink wh={18} color="#235FE6" />
                          </div>
                        )}
                      </div>
                    </div>
                    {mission.user && (
                      <div className="flex gap-1 items-center">
                        <div>
                          <Signer wh={18} color="#374151" />
                        </div>
                        {mission.contract_tempworker[0]?.id_bestt
                          ? `Contrat n°${mission.contract_tempworker[0]?.id_bestt}`
                          : 'Auncun contrat BeSTT'}
                      </div>
                    )}
                    {mission.reference && (
                      <div className="flex gap-1 items-center">
                        <div>
                          <ReferenceIcon wh={18} color="#374151" />
                        </div>
                        Référence n°{mission.reference}
                      </div>
                    )}
                  </div>
                  {/* Display temporary worker details if available */}
                  {mission.user && (
                    <div
                      className="w-1/2 rounded-lg border border-gray-100 px-4 py-2.5 text-gray-500 hover:bg-gray-100"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openTempWorkerInfo(mission.user.user_id);
                      }}
                    >
                      <TempWorkerMission user={mission.user} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* Pagination component for navigating through mission pages */}
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      {/* Right side: Detailed view of the selected mission */}
      <div className="flex-1 overflow-hidden mission-container-body-detail">
        {editMission ? (
          <AdminMissionDetails
            missionId={editMission}
            searchMissionResult={searchMissionResult}
            setSearchMissionResult={setSearchMissionResult}
          />
        ) : (
          <EmptyState type="select_mission" />
        )}
      </div>
    </>
  );
};

export default MissionDisplay;
